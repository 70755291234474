import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import "./App.css";
import darkModeIcon from "./darkBT.gif"; // darkBT.gif 이미지 파일을 가져옵니다.
import logoQWER from "./log_QWER.jpeg"; // log_QWER 이미지 파일을 가져옵니다.
import endGif1 from "./end1.gif"; // end1.gif 이미지 파일을 가져옵니다.
import endGif2 from "./end2.gif"; // end2.gif 이미지 파일을 가져옵니다.
import endGif3 from "./end3.gif"; // end3.gif 이미지 파일을 가져옵니다.
import SnsPage from "./snsPage";
import FanCam from "./fanCams";
import Letter from "./letter";
import Broadcast from "./broadcast";
import { Analytics } from "@vercel/analytics/react";
import { useTranslation } from "react-i18next";
import i18n from "../src/lang/i18n";
const extractYouTubeID = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const formatDate = (dateObject) => {
  if (dateObject && dateObject.$date) {
    return dateObject.$date.split("T")[0]; // YYYY-MM-DD 형식으로 자르기
  }
  return ""; // 날짜가 유효하지 않으면 빈 문자열 반환
};

const isMobile = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

const getRandomEndGif = () => {
  const gifs = [endGif1, endGif2, endGif3];
  return gifs[Math.floor(Math.random() * gifs.length)];
};

const App = () => {
  const [darkMode, setDarkMode] = useState(true); // 기본 설정을 다크 모드로 변경
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const loader = useRef(null);
  const [endGif, setEndGif] = useState(getRandomEndGif());
  const [sortOrder, setSortOrder] = useState("asc");
  const [menuOpen, setMenuOpen] = useState(false); // 모바일 메뉴 상태 추가
  const [eventList, setEventList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const setIndexA = [1, 2];
  const { t } = useTranslation();
  const handleNavItemClick = (event) => {
    const navItems = document.querySelectorAll(".nav-item");
    navItems.forEach((item) => {
      if (item !== event.currentTarget) {
        item.classList.remove("active");
      }
    });
    event.currentTarget.classList.toggle("active");
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const itemsPerPage = 15;
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("/data.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.uploadDate.$date);
          const dateB = new Date(b.uploadDate.$date);
          return dateA - dateB;
        });
        setAllItems(sortedData);
        setItems(sortedData.slice(0, itemsPerPage));
      } catch (error) {
        console.error("Error loading JSON data:", error);
      }
      setIsLoading(false);
    };

    fetchData();

    const handleClickOutside = (event) => {
      if (!event.target.closest(".navbar")) {
        closeMenu();
      }
    };
    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  // const loadMoreItems = () => {
  //   if (items.length >= allItems.length) return; // 더 이상 로드할 데이터가 없으면 중지
  //   setIsLoading(true);
  //   const nextPage = page + 1;
  //   const startIndex = nextPage * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   setItems((prevItems) => [
  //     ...prevItems,
  //     ...allItems.slice(startIndex, endIndex),
  //   ]);
  //   setPage(nextPage);
  //   setIsLoading(false);
  // };
  const loadMoreItems = () => {
    const filteredItems = allItems.filter((item) => {
      return !selectedEvent || (item.tag && item.tag.includes(selectedEvent));
    });

    const sortedFilteredItems = filteredItems.sort((a, b) => {
      const dateA = new Date(a.uploadDate.$date);
      const dateB = new Date(b.uploadDate.$date);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });

    if (items.length >= sortedFilteredItems.length) return;

    setIsLoading(true);
    const nextPage = page + 1;
    const startIndex = nextPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setItems((prevItems) => [
      ...prevItems,
      ...sortedFilteredItems.slice(startIndex, endIndex),
    ]);
    setPage(nextPage);
    setIsLoading(false);
  };
  const handleScroll = () => {
    if (isLoading) return;
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= documentHeight * 0.7) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, items]);

  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const response = await fetch("/eplist.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEventList(data);
      } catch (error) {
        console.error("Error loading event list:", error);
      }
    };
    fetchEventList();
  }, []);

  const getYouTubeAppLink = (url) => {
    const videoId = extractYouTubeID(url);
    if (!videoId) return url;
    if (/Android/i.test(navigator.userAgent)) {
      return `intent://www.youtube.com/watch?v=${videoId}#Intent;package=com.google.android.youtube;scheme=https;end`;
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return `youtube://${videoId}`;
    }
    return url;
  };

  const handleThumbnailClick = (url) => {
    const link = isMobile() ? getYouTubeAppLink(url) : url;
    window.open(link, "_blank");
  };

  const handleEventFilter = (event) => {
    const newEvent = event === selectedEvent ? null : event;

    setSelectedEvent(newEvent);

    // 이벤트 필터 적용 시 정렬 순서를 "asc"로 설정
    setSortOrder("asc"); // 정렬 순서를 "asc"로 강제 설정

    const filteredItems = allItems.filter((item) => {
      return !newEvent || (item.tag && item.tag.includes(newEvent));
    });

    const sortedFilteredItems = filteredItems.sort((a, b) => {
      const dateA = new Date(a.uploadDate.$date);
      const dateB = new Date(b.uploadDate.$date);
      return dateA - dateB; // 오래된 항목이 위로 오도록 정렬
    });

    setItems(sortedFilteredItems.slice(0, itemsPerPage));
    setPage(0);
  };

  const handleSort = () => {
    // 정렬 순서를 업데이트한 후 아이템을 정렬하고 설정
    setSortOrder((prevOrder) => {
      const newOrder = prevOrder === "asc" ? "desc" : "asc"; // 정렬 순서를 토글
      const filteredItems = allItems.filter((item) => {
        return !selectedEvent || (item.tag && item.tag.includes(selectedEvent));
      });

      const sortedItems = filteredItems.sort((a, b) => {
        const dateA = new Date(a.uploadDate.$date);
        const dateB = new Date(b.uploadDate.$date);
        return newOrder === "asc" ? dateA - dateB : dateB - dateA;
      });

      setItems(sortedItems.slice(0, itemsPerPage));
      setPage(0);
      return newOrder;
    });
  };
  const onChangeLang = () => {
    i18n.language === "ko"
      ? i18n.changeLanguage("en")
      : i18n.changeLanguage("ko");
  };
  return (
    <Router>
      <div className={`App ${darkMode ? "dark-mode" : "light-mode"}`}>
        <nav className="navbar">
          <div className="hamburger" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={menuOpen ? "active" : ""}>
            <li className="nav-item" onClick={closeMenu}>
              <Link to="/">{t("YouTube")}</Link>
              <ul className="dropdown"></ul>
            </li>
            <li className="nav-item" onClick={closeMenu}>
              <Link to="/sns">{t("SNS")}</Link>
              <ul className="dropdown"></ul>
            </li>
            <li className="nav-item" onClick={closeMenu}>
              <Link to="/fancam">{t("FanCam")}</Link>
              <ul className="dropdown"></ul>
            </li>
            <li className="nav-item" onClick={closeMenu}>
              <Link to="/broadcast">{t("ROKA")}</Link>
              <ul className="dropdown"></ul>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <>
                  <h1>{t("YouTube")}</h1>
                  <h3>{t("devMemo")}</h3>
                  <p>
                    {t("totVideos")}: {allItems.length}
                  </p>
                </>
                <button
                  className="toggle-button"
                  onClick={() => setDarkMode(!darkMode)}
                >
                  <div className="bwicon"></div>
                  <span>{t("switMode")}</span>
                </button>
                <a href="https://youtu.be/xDTyCpZcStE" target="_blank">
                  {t("penta")}
                </a>
                <button className="lng-bt" onClick={onChangeLang}>
                  KR ↔ ENG
                </button>

                <button className="sort-button" onClick={handleSort}>
                  {sortOrder === "asc" ? t("sortNew") : t("sortOld")}
                </button>
                <div className="event-picker">
                  {eventList.map((event, index) => (
                    <div
                      key={index}
                      className={`event-item ${
                        selectedEvent === event ? "selected" : ""
                      }`}
                      onClick={() => handleEventFilter(event)}
                    >
                      {t(event)}
                    </div>
                  ))}
                </div>
                <div className="timeline">
                  {items.map((item, index) => {
                    const videoId = extractYouTubeID(item.url);
                    const thumbnailUrl = videoId
                      ? `https://img.youtube.com/vi/${videoId}/0.jpg`
                      : "";
                    const titleClass =
                      item.title.length > 10 ? "scrolling-title" : "";
                    return (
                      <div
                        className={`timeline-item ${
                          index % 2 === 0 ? "left" : "right"
                        }`}
                        key={item._id}
                      >
                        <div className="circle">
                          <img
                            src={logoQWER}
                            alt="QWER Logo"
                            className="circle-img"
                          />
                        </div>
                        <div className="content">
                          {thumbnailUrl && (
                            <img
                              src={thumbnailUrl}
                              alt={item.title}
                              className="thumbnail"
                              onClick={() => handleThumbnailClick(item.url)}
                            />
                          )}
                          <div className="title-container">
                            <h2 className={titleClass}>{item.title}</h2>
                          </div>{" "}
                          <p>{formatDate(item.uploadDate)}</p>
                          <a
                            href={
                              isMobile()
                                ? getYouTubeAppLink(item.url)
                                : item.url
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("watch")}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                  <div ref={loader} className="loader">
                    {isLoading && <p>Loading...</p>}
                  </div>
                  <div className="end-gif">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1iURMwU8WYtYzQn9KPxGPANn6ugUKtGFBXL2zvovzRhg/edit?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={endGif} alt="End" />
                    </a>
                  </div>

                  <a> 🐣건의사항및 영상추가 요청은 ⬆ 클릭</a>
                  <Analytics />
                </div>
                <footer className="footer">{t("footer")}</footer>
              </>
            }
          ></Route>
          <Route
            path="/sns"
            element={
              <SnsPage
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                language={t}
                langbt={onChangeLang}
              />
            }
          />
          <Route
            path="/fancam"
            element={
              <FanCam
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                language={t}
                langbt={onChangeLang}
              />
            }
          />
          <Route
            path="/broadcast"
            element={
              <Broadcast
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                language={t}
                langbt={onChangeLang}
              />
            }
          />
          {/* <Route
            path="/letter"
            element={
              <Letter
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                language={t}
                langbt={onChangeLang}
              />
            }
          /> */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
